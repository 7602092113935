<template>
  <div>
    <v-card elevation="0" class="px-5 py-5">
      <v-row>
        <v-col md="3">
          <v-text-field :dark="$store.state.isDarkMode" :label="$t('hr.time.from-date')" type="date"
            v-model="filterData.from_date" clearable>
          </v-text-field>

        </v-col>
        <v-col md="3">
          <v-text-field :dark="$store.state.isDarkMode" :label="$t('hr.time.to-date')" type="date"
            v-model="filterData.to_date" clearable>
          </v-text-field>
        </v-col>
        <v-col md="3">
          <v-autocomplete :dark="$store.state.isDarkMode" v-model="filterData.user" :label="$t('user name')"
            :items="userItems" item-text="name" item-value="id" :loading="userItemsLoading" no-filter color="#757575"
            @keypress="fetchUsersItemsAutoComplete"></v-autocomplete>
        </v-col>
        <v-col md="3">
          <v-text-field :dark="$store.state.isDarkMode" :label="$t('number')" v-model="filterData.id"
            type="number"></v-text-field>
        </v-col>
        <v-col md="2">
          <v-text-field :dark="$store.state.isDarkMode" :disabled="disableDebit" :label="$t('debtor')"
            v-model="filterData.debit" type="number"></v-text-field>
        </v-col>
        <v-col md="2">
          <v-text-field :dark="$store.state.isDarkMode" :disabled="disableCredit" :label="$t('credit')"
            v-model="filterData.credit" type="number"></v-text-field>
        </v-col>
        <v-col md="2">
          <v-autocomplete :dark="$store.state.isDarkMode" :label="$t('hr.employee.check.account')" :items="accountItems"
            :loading="accountItemsLoading" color="#757575" item-text="name" item-value="id" v-model="filterData.account"
            no-filter @keypress="fetchAutoCompleteAccount">
          </v-autocomplete>
        </v-col>
        <v-col md="2">
          <v-text-field :dark="$store.state.isDarkMode" :label="$t('explain')" v-model="filterData.desc"></v-text-field>
        </v-col>
        <v-col md="2">
          <v-autocomplete :dark="$store.state.isDarkMode" :label="$t('cost center')" :items="costCenterItems"
            color="#757575" item-text="name" item-value="id" :loading="costCenterItemsLoading"
            v-model="filterData.costCenter" @keypress="fetchAutoCompleteCostCenter" no-filter>
          </v-autocomplete>
        </v-col>
        <v-col md="2" class="d-flex justify-space-around align-center">
          <v-icon large :dark="$store.state.isDarkMode" @click="resetFilter">mdi-close</v-icon>
          <v-icon large :dark="$store.state.isDarkMode" @click="filterTable">mdi-magnify</v-icon>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
import axios from "axios";
import debounce from "../../../../helpers/debounce";
import Notifications from "../../../../helpers/toast-notifications";
export default {
  data() {
    return {
      fromDialog: false,
      toDialog: false,
      filterData: {
        user: null,
        id: null,
        debit: null,
        credit: null,
        desc: "",
        costCenter: null,
        from_date: "",
        to_date: "",
        page: 1,
        account: null,
      },
      menu: false,
      userItems: [],
      userItemsLoading: false,
      accountItems: [],
      accountItemsLoading: false,
      costCenterItems: [],
      costCenterItemsLoading: false,
      validDate: false,
    };
  },
  computed: {
    disableCredit() {
      return !!this.filterData.debit;
    },
    disableDebit() {
      return !!this.filterData.credit;
    },
  },
  methods: {
    resetFilter() {
      this.filterData.user = null;
      this.filterData.id = null;
      this.filterData.debit = null;
      this.filterData.credit = null;
      this.filterData.desc = "";
      this.filterData.costCenter = null;
      this.filterData.from_date = "";
      this.filterData.to_date = "";
      this.filterData.account = null;
      this.$emit("resetFilter");
      localStorage.removeItem('filterAccountingEntry');
    },
    checkValidation() {
      if (this.filterData.from_date && this.filterData.to_date) {
        if (
          new Date(this.filterData.from_date) >
          new Date(this.filterData.to_date)
        ) {
          return false;
        } else {
          return true;
        }
      } else {
        return true;
      }
    },
    filterTable() {
      if (this.checkValidation()) {
        this.$emit("filterTable", this.filterData);
        localStorage.setItem("filterAccountingEntry",JSON.stringify(this.filterData));
        console.log(".skdfkdsf.dsknfdk.snfdk.sfnsd.kfnd.skfndsk.fndsk.fn");
      } else {
        Notifications(
          "التاريخ المدخل خاطئ",
          { timeout: 4000, rtl: true },
          "info"
        );
      }
    },
    async fetchUsersItemsAutoComplete(searchQuery) {
      debounce(async () => {
        let searchValue = searchQuery.target.value;
        this.userItemsLoading = true;
        if (!searchValue) return;
        try {
          const searchResult = await axios.get("/user/auto-complete", {
            params: {
              name: searchValue,
            },
          });
          this.userItems = searchResult.data.users;
        } catch (err) {
          console.log("err", err);
        } finally {
          this.userItemsLoading = false;
        }
      }, 500)();
    },
    async fetchAutoCompleteAccount(searchQuery) {
      debounce(async () => {
        let searchValue = searchQuery.target.value;
        this.accountItemsLoading = true;
        if (!searchValue) return;
        try {
          const searchResult = await axios.get(
            "/accounting/account/auto-complete",
            {
              params: {
                query: searchValue,
              },
            }
          );
          this.accountItems = searchResult.data.data;
        } catch (err) {
          console.log("err", err);
        } finally {
          this.accountItemsLoading = false;
        }
      }, 500)();
    },
    async fetchAutoCompleteCostCenter(searchQuery) {
      debounce(async () => {
        let searchValue = searchQuery.target.value;
        this.costCenterItemsLoading = true;
        if (!searchValue) return;
        try {
          const searchResult = await axios.get(
            "/accounting/cost-center/auto-complete",
            {
              params: {
                query: searchValue,
              },
            }
          );
          this.costCenterItems = searchResult.data.data;
        } catch (err) {
          console.log("err", err);
        } finally {
          this.costCenterItemsLoading = false;
        }
      }, 500)();
    },
  },
  created(){
    if(JSON.parse(localStorage.getItem("filterAccountingEntry"))){
      this.filterData = JSON.parse(localStorage.getItem("filterAccountingEntry"));
      this.filterTable();
    }
    if(this.$route.params.id){
          this.filterData.id = this.$route.params.id;
        }

  }
};
</script>

<style></style>